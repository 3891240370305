<template>
  <a-row v-if="isLoading" class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="18" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="document_files_array.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  :href="form.file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
              <!-- <a :href="form.file">{{ form.file }}</a> -->
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="form_title" style="padding-top: 2px">
              Изображение:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <div class="committee-image">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 15px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="4" />
            <a-col :span="12">
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Отмена
              </a-button>
              <a-button :loading="pending" type="primary" @click="submit">
                Сохранить
              </a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item"
import { required } from "vuelidate/lib/validators"

export default {
  components: { InputFileItem },
  data: () => {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        photo: "",
        is_active: false
      },
      fileList: [],
      langTab: "oz",
      image: null,
      pending: false,
      image_url: "",
      isLoading: false,
      document_files_array: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async created() {
    this.isLoading = false
    const form = await this.$store.dispatch(
      "resources/fetchResourceById",
      this.$route.params.id
    )
    this.form = { ...form }
    if (this.form.file) {
      this.document_files_array.push({
        binary_file: {
          file: this.form.file,
          name: this.form.file.split("/")[this.form.file.split("/").length - 1]
        }
      })
      // console.log(this.document_files_array)
    }
    this.image_url = form.photo
    this.isLoading = true
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submit() {
      this.pending = true
      const id = this.$route.params.id
      try {
        let form = this.form
        form = {
          ...form
        }
        delete form.images

        if (typeof form.photo === "string") {
          delete form.photo
        }
        if (typeof form.file === "string") {
          delete form.file
        }

        let f = new FormData()
        Object.keys(form).forEach((key) => {
          if (form[key] === null) {
            form[key] = ""
          }
          f.append(key, form[key])
        })
        if (this.document_files_array[0] && this.document_files_array[0].uid) {
          this.document_files_array.forEach((item) => {
            item &&
              item.binary_file &&
              !item.binary_file.id &&
              f.append("file", item.binary_file, item.binary_file.name)
          })
        }
        await this.$api.patch(`/admin/common/resource/${id}/update/`, f)
        this.$message.success(" Изменена успешно!")
        // await this.$router.push({ name: "resources" })
        this.$router.go(-1)
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }

      this.pending = false
    },
    deleteDocumentFileFromState(e) {
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.document_files_array, e)
    },
    handleImages(arg) {
      this.fileList = arg
    },
    newsDocs() {
      return (
        this.newsFiles && this.newsFiles.filter((item) => item.file_type === 2)
      )
    },
    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0], "dfhdjfhjdfhdjfdfhd")
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.photo = e.target.files[0]
    },
    handleDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("array", this.document_files_array)
      this.$refs.documentRef.value = null
    }
  },
  validations: {
    form: {
      photo: {
        required
      },
      documnet: {
        required
      }
    }
  }
}
</script>

<style scoped>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
</style>
